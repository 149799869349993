<template>
  <container id="footer" color="blue-dark">
      <footer class="footer">
          <div class="footer__logo">
              <img src="@/assets/img/doe-logo-white.svg" width="184.6" height="125.2"  alt="digital on earth logo" class="footer__logo--img">
          </div>
            <div class="footer__line footer__line--contact">
                <div class="footer__line--item">
                    <h2 class="footer__heading">{{t('title')}}</h2>
                    <h3 class="footer__subheading">{{t('subtitle')}}</h3>
                </div>
                <div class="footer__liner--item">
                    <a href="mailto:info@onearth.be" class="btn btn--white">
                        {{t('contact')}}
                    </a>
                </div>
            </div>

            <div class="footer__line footer__line--action">
                <div class="footer__line--item">
                    <a href="mailto:info@onearth.be" class="footer__contact">
                        <Svg icon="mail" className="footer__contact--svg"/>
                        info@onearth.be
                    </a>
                </div>
                 <div class="footer__line--item">
                    <a href="tel:0032497331008" class="footer__contact">
                        <Svg icon="phone" className="footer__contact--svg"/>
                        +32 497 33 10 08
                    </a>
                </div>
                 <div class="footer__line--item">
                    <div class="footer__contact">
                        <Svg icon="home" className="footer__contact--svg"/>
                        1384/5 chaussée de waterloo<br>
                        B-1180 uccle
                    </div>
                </div>
            </div>

            <div class="footer__line footer__line--bottom">
                <div class="footer__line--item">
                    TVA : BE.0763.581.921
                </div>
            </div>
      </footer>
  </container>
</template>

<script>
import Container from './Container'
import Svg from './utils/Svg';

import {useI18n} from 'vue-i18n';

export default {
    components: {Container, Svg},
    setup(){
          const {t} = useI18n({
            messages:{
              en: require('../locales/en/footer.json'),
              fr: require('../locales/fr/footer.json')
            }
        })

        return {t}

    }

}
</script>

<style lang="scss" scoped>

@import '@/style/main';

.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__logo{
        margin-bottom: 2rem;
        &--img{
            width: auto;
            height: 8rem;
        }
    }

    &__heading{
        text-transform: uppercase;
        margin-bottom: .4rem;
    }

    &__subheading{
        text-transform: uppercase;
        font-weight: 300;
    }


    &__line{
        width: 100%;
        display: flex;
        justify-content: space-between;

        &--item{
            @include respond(phone){
                margin-bottom: 2rem;
            }
        }

        @include respond(phone){
            flex-direction: column;
        }
        

        &--contact{
            margin-bottom: 4rem;

        }

        &--action{
            margin-bottom: 3rem
        }

        &--bottom{
            border-top: 2px solid currentColor;
            padding-top: 1rem
        }
    }
    &__contact{
        @include buttonNoStyle;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: .1rem;
        &--svg{
            @include svg(3rem);
            margin-right: 1.5rem;
        }
    }
    
}

</style>